import React, { useState, useEffect } from "react";
import lamp from "../assets/images/lamp.svg";
import { Link } from "react-router-dom";
import axios from "axios";

function HomePage() {
  const [mohafazat, setMohafazat] = useState([]);
  const [mohafazatAr, setMohafazatAr] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://api.solarcalculator.energyhub-lb.com/api.php?action=mouhafazat"
      )
      .then((res) => {
        setMohafazat(Object.entries(res.data.data));
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        "https://api.solarcalculator.energyhub-lb.com/api.php?action=mouhafazat&lang=ar"
      )
      .then((res) => {
        setMohafazatAr(Object.entries(res.data.data));
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  return (
    <div className="text-center">
      <div className="homepage mx-lg-auto">
        <div className="homepage-image-height">
          <img src={lamp} alt="" className="mt-lg-4 mt-md-4 mt-2 image-size" />
        </div>
        <h1 className="mt-md-3 mt-lg-3 mb-0">
          Your solar
          <br /> energy calculator
        </h1>
        <h1 className="pt-3 font-arabic-semibold">
          آلتك الحاسبة للطاقة
          <br /> الشمسية
        </h1>
        <p className="mt-3 mx-4">
          In the face of global energy crisis, we all have become "energy
          managers" in our own home.
        </p>
        <p className="mx-4">
          Our engineers developed this calculator to guide you on solar energy
          load management for your home.
        </p>
        <p className="pt-lg-2 pt-md-2 mx-2 font-arabic-regular direction mx-4">
          في مواجهة أزمة الطاقة العالمية، لقد أصبحنا جميعاً "منسيقن للحمل
          الكهربائي" في منازلنا.
        </p>
        <p className="mx-2 font-arabic-regular direction mx-4">
          طوّر مهندسو فريق الطاقة البديلة لدينا هذه الآلة الحاسبة لإرشادك بشأن
          الطاقة الشمسية لمنزلك.
        </p>
        <div className="mt-2">
          <Link to="/location" state={{ mohafazat: mohafazat }}>
            <button type="button" className="rounded-pill">
              Start Here
            </button>
          </Link>
        </div>
        <div>
          <Link to="/location-ar" state={{ mohafazat: mohafazatAr }}>
            <button
              type="button"
              className="rounded-pill mt-2 mb-4 font-arabic-regular"
            >
              للاستكمال بالعربية
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;

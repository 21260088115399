import React, { useState, useEffect } from "react";
import eyes from "../assets/images/eyes.svg";
import zone1 from "../assets/images/zone1.png";
import zone2 from "../assets/images/zone2.png";
import zone3 from "../assets/images/zone3.png";
import zone4 from "../assets/images/zone4.png";

import { Link, useLocation } from "react-router-dom";
import axios from "axios";

function Location() {
  const location = useLocation();
  const [mohafazat, setMohafazat] = useState([]);
  const [mohafazaPicked, setMohafazaPicked] = useState("");
  const [casas, setCasas] = useState([]);
  const [casaPicked, setCasaPicked] = useState("");
  const [areas, setAreas] = useState([]);
  const [areaPicked, setAreaPicked] = useState("");
  const [mohafazaName, setMohafazaName] = useState("");
  const [casaName, setCasaName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [region, setRegion] = useState(false);
  const [newArea, setNewArea] = useState([]);
  const [locationPicked, setLocationPicked] = useState(false);
  const [zoneArea, setZoneArea] = useState("");
  const [zone_image, setZone_image] = useState("");

  useEffect(() => {
    if (location.state) {
      setMohafazat(location.state.mohafazat);
    }
  }, [location.state]);

  useEffect(() => {
    if (areaPicked && mohafazaPicked && casaPicked) {
      setLocationPicked(true);
    } else {
      setLocationPicked(false);
    }
  }, [areaPicked, mohafazaPicked, casaPicked]);

  useEffect(() => {
    if (mohafazaPicked) {
      axios
        .get(
          `https://api.solarcalculator.energyhub-lb.com/api.php?action=cazas&mouhafazaId=${mohafazaPicked}`
        )
        .then((res) => {
          setCasas(Object.entries(res.data.data));
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [mohafazaPicked]);

  useEffect(() => {
    if (casaPicked) {
      axios
        .get(
          `https://api.solarcalculator.energyhub-lb.com/api.php?action=regions&cazaId=${casaPicked}`
        )
        .then((res) => {
          setAreas(Object.entries(res.data.data));
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [casaPicked]);

  useEffect(() => {
    if (areas) {
      areas.map((item) => {
        newArea.push({ id: item[0], region: item[1] });
        setRegion(true);
        return newArea;
      });
      newArea.sort(function(a, b) {
        if (a.region < b.region) {
          return -1;
        }
        if (a.region > b.region) {
          return 1;
        }
        return 0;
      });
    }
  }, [areas]);

  useEffect(() => {
    if (areaPicked) {
      axios
        .get(
          `https://api.solarcalculator.energyhub-lb.com/api.php?action=area_zone&regionId=${areaPicked}`
        )
        .then((res) => {
          setZoneArea(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [areaPicked]);

  useEffect(() => {
    if (zoneArea == 1) {
      setZone_image(zone1);
    } else if (zoneArea == 2) {
      setZone_image(zone2);
    } else if (zoneArea == 3) {
      setZone_image(zone3);
    } else if (zoneArea == 4) {
      setZone_image(zone4);
    } else {
      setZone_image(eyes);
    }
  }, [zoneArea]);

  return (
    <div>
      <div className="location-page mx-lg-auto location_page">
        <div className="location-image-height mb-2">
          <img src={zone_image} alt="" className="mt-lg-5 mt-md-5 mt-2" />
        </div>
        <h1 className="mt-lg-4 mt-md-4 mb-0 mx-4">
          Would you like to set the residential location?
        </h1>
        <h3>
          This is essential for the calculator to give you the right zone based
          on the number of sunny days this residence gets per year.
        </h3>
        <div>
          <button
            type="button"
            className={`rounded-pill mt-3 location-btn ${mohafazaPicked &&
              "rounded-pill mt-3 location-btn-checked"}`}
          >
            <select
              name="mohafaza"
              className="form-select"
              onChange={(e) => {
                setMohafazaName(e.target.selectedOptions[0].innerHTML);
                setMohafazaPicked(e.target.value);
              }}
            >
              <option>Mohafaza</option>
              {mohafazat.map((mohafaza, index) => {
                return (
                  <option key={index} value={mohafaza[0]}>
                    {mohafaza[1]}
                  </option>
                );
              })}
            </select>
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`rounded-pill mt-1 location-btn ${casaPicked &&
              "rounded-pill mt-1 location-btn-checked"}`}
          >
            <select
              name="casa"
              className="form-select"
              onChange={(e) => {
                setCasaName(e.target.selectedOptions[0].innerHTML);
                setCasaPicked(e.target.value);
              }}
            >
              <option>Casa</option>
              {casas.map((casa, index) => {
                return (
                  <option key={index} value={casa[0]}>
                    {casa[1]}
                  </option>
                );
              })}
            </select>
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`rounded-pill mt-1 location-btn ${areaPicked &&
              "rounded-pill mt-1 location-btn-checked"}`}
          >
            <select
              name="area"
              className="form-select"
              onChange={(e) => {
                setRegionName(e.target.selectedOptions[0].innerHTML);
                setAreaPicked(e.target.value);
              }}
            >
              <option>Area</option>
              {areas.map((area, index) => {
                return (
                  <option key={index} value={area[0]}>
                    {area[1]}
                  </option>
                );
              })}
              {/* {newArea.map((area, index) => {
                return (
                  <option key={index} value={area.id}>
                    {area.region}
                  </option>
                );
              })} */}
            </select>
          </button>
        </div>

        <Link
          to="/energy-sources"
          state={{
            areaPicked: areaPicked,
            mohafazaName: mohafazaName,
            casaName: casaName,
            regionName: regionName,
          }}
        >
          <button
            className="next-btn mt-2 rounded-pill mb-lg-5 mb-md-5 mb-2"
            type="button"
            disabled={!locationPicked}
          >
            Next
          </button>
        </Link>
        <div className=" position-sticky footer-style pb-4">
          <p className="ms-4 text-lg-center text-md-center text-sm-center text-start mb-2 mt-lg-4 mt-md-4 mt-2">
            Try to answer all the questions to get your 5 stars energy
            management report
          </p>

          {locationPicked ? (
            <div className="d-flex  justify-content-center ">
              <div className={`active box`}></div>
              <div className={`inactive box`}></div>
              <div className={`inactive box`}></div>
              <div className={`inactive box`}></div>
              <div className={`inactive box`}></div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
                <div className={`inactive box`}></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Location;
